const ROOT_URL = '/';
const SERVICES_URL = '/our-expertise/';
const PROJECTS_URL = '/how-we-work/';
const ABOUT_US_URL = '/partnership/';
const CONTACT_US_URL = '/get-in-touch/';
const TERMS_URL = '/terms/';
const PRIVACY_POLICY_URL = '/privacy/';
const MYTOKEN_COOKIE_NAME = 'userId';
const MYTOKEN_LOCALSTORAGE_KEY = 'userId';

function saveToken(myToken) {
  document.cookie = `${MYTOKEN_COOKIE_NAME}=${myToken}; path=/`;
  localStorage.setItem(MYTOKEN_LOCALSTORAGE_KEY, myToken);
}

function retrieveToken() {
  return localStorage.getItem(MYTOKEN_LOCALSTORAGE_KEY) ?? '';
}

function redirectToPageIfNeeded(webPathname) {
  const retrievedToken = retrieveToken();
  if (!webPathname.includes(retrievedToken)) {
    const newPagePath = `${webPathname}${retrievedToken}`;
    window.history.pushState(null, null, newPagePath);
  }

  return retrievedToken;
}

function handleDefault(tokenQuery) {
  saveToken(tokenQuery);
}

function sendDataGtm() {
  var userId = retrieveToken();
  if (userId.length > 0) {
    const regex = /\?t=/gi;
    const result = userId.replace(regex, '');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: result, // Replace this with an actual User ID
    });
  }
}

const pathHandler = {
  handleRoot: (tokenQuery) => {
    if (tokenQuery) {
      saveToken(tokenQuery);
    } else {
      redirectToPageIfNeeded(ROOT_URL);
    }
  },
  handleServices: () => {
    redirectToPageIfNeeded(SERVICES_URL);
  },
  handleAboutUs: () => {
    redirectToPageIfNeeded(ABOUT_US_URL);
  },
  handleContactUs: () => {
    redirectToPageIfNeeded(CONTACT_US_URL);
  },
  handleProjects: () => {
    redirectToPageIfNeeded(PROJECTS_URL);
  },
  handleTerms: () => {
    redirectToPageIfNeeded(TERMS_URL);
  },
  handlePrivacyPolicy: () => {
    redirectToPageIfNeeded(PRIVACY_POLICY_URL);
  },
};

const routes = {
  [ROOT_URL]: pathHandler.handleRoot,
  [SERVICES_URL]: pathHandler.handleServices,
  [PROJECTS_URL]: pathHandler.handleProjects,
  [ABOUT_US_URL]: pathHandler.handleAboutUs,
  [CONTACT_US_URL]: pathHandler.handleContactUs,
  [TERMS_URL]: pathHandler.handleTerms,
  [PRIVACY_POLICY_URL]: pathHandler.handlePrivacyPolicy,
};

exports.onRouteUpdate = () => {
  const pathname = window.location.pathname;
  const tokenQuery = window.location.search;
  const handler = routes[pathname + tokenQuery] ?? handleDefault;
  handler(tokenQuery);
  sendDataGtm(tokenQuery);
};
