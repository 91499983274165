exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-get-in-touch-index-js": () => import("./../../../src/pages/get-in-touch/index.js" /* webpackChunkName: "component---src-pages-get-in-touch-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-expertise-index-js": () => import("./../../../src/pages/our-expertise/index.js" /* webpackChunkName: "component---src-pages-our-expertise-index-js" */),
  "component---src-pages-partnership-index-js": () => import("./../../../src/pages/partnership/index.js" /* webpackChunkName: "component---src-pages-partnership-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-unsubscribe-index-js": () => import("./../../../src/pages/unsubscribe/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-index-js" */)
}

